import '../styles/index.scss';

import ScrollTrigger from '@terwanerik/scrolltrigger';
// Create a new ScrollTrigger instance with default options
const trigger = new ScrollTrigger(); // When not using npm, create a new instance with 'new ScrollTrigger.default()'
// Add all html elements with attribute data-trigger

trigger.add('[data-slideInLeft]', { once: false });
trigger.add('[data-slideInRight]', { once: false });
trigger.add('[data-slideInBottom]', { once: false });
trigger.add('[data-slideInTop]', { once: false });